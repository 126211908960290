import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Center,
  GridItem,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Table,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@connectrpc/connect-query";
import { getPlayerProfile } from "proto/user/v1/user_api-UserAPI_connectquery";
import {
  LevelOfTournamentParticipatedType,
  PlayerProfile,
} from "proto/user/v1/user_api_pb";
import { GenderType } from "proto/user/v1/user_api_pb";
import { HandednessType } from "proto/user/v1/user_api_pb";
import othersportIcon from "board/assets/othersport.png";
import badmintionIcon from "board/assets/badminton.png";
import tennisIcon from "board/assets/tennis.png";
import basketballIcon from "board/assets/basketball.png";
import footballIcon from "board/assets/football.png";
import tableTennisIcon from "board/assets/tableTennis.png";
import { IoIosFemale, IoIosMale } from "react-icons/io";
import { QuestionIcon } from "@chakra-ui/icons";
import { getUserAvatar } from "helpers";
import { Sport } from "proto/base/v1/whiteboard_pb";

const sports = [
  {
    value: Sport.OTHER,
    title: "Other Sport",
    color: "gray.700",
    bgColor: "gray.300",
    image: othersportIcon,
  },
  {
    value: Sport.BADMINTION,
    title: "Badmintion",
    color: "blue.700",
    bgColor: "blue.100",
    image: badmintionIcon,
  },
  {
    value: Sport.TENNIS,
    title: "Tennis",
    color: "purple.700",
    bgColor: "purple.100",
    image: tennisIcon,
  },
  {
    value: Sport.BASKETBALL,
    title: "Basketball",
    color: "yellow.700",
    bgColor: "yellow.100",
    image: basketballIcon,
  },
  {
    value: Sport.FOOTBALL,
    title: "Football",
    color: "red.700",
    bgColor: "red.100",
    image: footballIcon,
  },
  {
    value: Sport.TABLE_TENNIS,
    title: "Table Tennis",
    color: "green.700",
    bgColor: "green.100",
    image: tableTennisIcon,
  },
];

export const PlayerProfileCard = ({
  playerProfile,
}: {
  playerProfile?: PlayerProfile;
}) => {
  return (
    <VStack mt={2}>
      <HStack w="90%">
        <SimpleGrid columns={7} w="100%">
          <GridItem colSpan={3}>
            <Center w="100%">
              <VStack w="100%">
                <Avatar
                  size={"xl"}
                  src={getUserAvatar(playerProfile?.avatarKey)}
                />
                <Center>
                  <Text fontSize={"md"} textAlign={"center"}>
                    {playerProfile?.firstName + " " + playerProfile?.lastName}
                  </Text>
                </Center>
              </VStack>
            </Center>
          </GridItem>
          <GridItem colSpan={4}>
            <Table size={"sm"}>
              <Tr border="solid" borderWidth={"1px"}>
                <Td border="solid" borderWidth={"1px"}>
                  DOB
                </Td>
                <Td border="solid" borderWidth={"1px"}>
                  {playerProfile?.dob?.toDate().toLocaleDateString()}
                </Td>
              </Tr>
              <Tr border="solid" borderWidth={"1px"}>
                <Td border="solid" borderWidth={"1px"}>
                  Local ID
                </Td>
                <Td border="solid" borderWidth={"1px"}>
                  {playerProfile?.localId}
                </Td>
              </Tr>
              <Tr border="solid" borderWidth={"1px"}>
                <Td border="solid" borderWidth={"1px"}>
                  India ID
                </Td>
                <Td border="solid" borderWidth={"1px"}>
                  {playerProfile?.nationalId}
                </Td>
              </Tr>
              <Tr border="solid" borderWidth={"1px"}>
                <Td border="solid" borderWidth={"1px"}>
                  Intl ID
                </Td>
                <Td border="solid" borderWidth={"1px"}>
                  {playerProfile?.internationalId}
                </Td>
              </Tr>
            </Table>
          </GridItem>
        </SimpleGrid>
      </HStack>
      <SimpleGrid columns={5} spacing={2} w="90%" mt={2}>
        <GridItem colSpan={5} rowSpan={1}>
          <Card shadow={"lg"} bg={"lavender"}>
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"gray.600"} textAlign={"center"}>
                  {"Current Level"}
                </Heading>
              </Center>
              <VStack>
                <Center>
                  <Text
                    textAlign={"center"}
                    fontSize={"xl"}
                    color={"green.900"}
                  >
                    {LevelOfTournamentParticipatedType[
                      playerProfile?.levelOfTournamentParticipated ??
                        LevelOfTournamentParticipatedType.AMATEUR
                    ].replaceAll("_", " ")}
                  </Text>
                </Center>
              </VStack>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem colSpan={2} rowSpan={2}>
          <Card
            shadow={"lg"}
            bg={
              sports.find((s) => playerProfile?.sport === s.value)?.bgColor ??
              "lightskyblue"
            }
            w="100%"
            h={"100%"}
          >
            <CardHeader>
              {/* <Center fontSize={'4xl'}>
                  {playerProfile?.handedness === HandednessType.BOTH ? 'B' : playerProfile?.handedness === HandednessType.RIGHT ? 'R'
                    : playerProfile?.handedness === HandednessType.LEFT ? 'L' : '?'}
                </Center> */}
              <Image
                src={
                  sports.find((s) => playerProfile?.sport === s.value)?.image ??
                  othersportIcon
                }
                w="100%"
              />
            </CardHeader>
            <Heading
              size={"md"}
              color={
                sports.find((s) => playerProfile?.sport === s.value)?.color ??
                "gray.600"
              }
              textAlign={"center"}
              m={2}
            >
              {sports.find((s) => playerProfile?.sport === s.value)?.title ??
                "Other Sport"}
            </Heading>
            <Text m={2} textAlign={"center"}>
              {playerProfile?.tournamentEvents === 1
                ? "Individual"
                : playerProfile?.tournamentEvents === 2
                  ? "Team"
                  : "Team & Individual"}
            </Text>
          </Card>
        </GridItem>
        <GridItem colSpan={1} rowSpan={1}>
          <Card shadow={"lg"} bg="gray.300" w="100%" h="100%">
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"gray.600"} textAlign={"center"}>
                  {"Pref. Side"}
                </Heading>
              </Center>
              <Center fontSize={"4xl"}>
                {playerProfile?.handedness === HandednessType.BOTH
                  ? "B"
                  : playerProfile?.handedness === HandednessType.RIGHT
                    ? "R"
                    : playerProfile?.handedness === HandednessType.LEFT
                      ? "L"
                      : "?"}
              </Center>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem colSpan={2} rowSpan={1}>
          <Card shadow={"lg"} bg={"wheat"} h="100%">
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"gray.600"} textAlign={"center"}>
                  {"Height (cm)"}
                </Heading>
              </Center>
              <VStack>
                <Center>
                  <Text fontSize={"4xl"}>{playerProfile?.height}</Text>
                </Center>
              </VStack>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem colSpan={1} rowSpan={1}>
          <Card
            h="100%"
            shadow={"lg"}
            bg={
              playerProfile?.gender === GenderType.MALE
                ? "blue.100"
                : playerProfile?.gender === GenderType.FEMALE
                  ? "pink"
                  : "purple.100"
            }
            w="100%"
          >
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"gray.600"} textAlign={"center"}>
                  {"Gender"}
                </Heading>
              </Center>
              <Center mt="4">
                <Text fontSize={"4xl"}>
                  {playerProfile?.gender === GenderType.MALE ? (
                    <IoIosMale />
                  ) : playerProfile?.gender === GenderType.FEMALE ? (
                    <IoIosFemale />
                  ) : (
                    <QuestionIcon />
                  )}
                </Text>
              </Center>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem colSpan={2} rowSpan={1}>
          <Card shadow={"lg"} bg={"powderblue"} h="100%">
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"gray.600"} textAlign={"center"}>
                  {"Weight (kg)"}
                </Heading>
              </Center>
              <VStack>
                <Center>
                  <Text fontSize={"4xl"}>{playerProfile?.weight}</Text>
                </Center>
              </VStack>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem colSpan={2} rowSpan={1} w="100%">
          <Card shadow={"lg"} bg="yellow.100" h="100%">
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"goldenrod"} textAlign={"center"}>
                  {"Medals"}
                </Heading>
              </Center>
              <Center>
                <Text
                  textAlign={"center"}
                  fontSize={"4xl"}
                  color={"yellow.800"}
                >
                  {playerProfile?.podiumFinishes}
                </Text>
              </Center>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem colSpan={3} rowSpan={1}>
          <Card shadow={"lg"} bg={"thistle"} h="100%">
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"gray.600"} textAlign={"center"}>
                  {"Experience (yrs)"}
                </Heading>
              </Center>
              <VStack>
                <Center>
                  <Text
                    textAlign={"center"}
                    fontSize={"4xl"}
                    color={"green.900"}
                  >
                    {playerProfile?.noOfYearsPlaying}
                  </Text>
                </Center>
              </VStack>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem colSpan={1} rowSpan={1}>
          <Card shadow={"lg"} bg="green.100" w="100%" h="100%">
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"green"} textAlign={"center"}>
                  {"Tours"}
                </Heading>
              </Center>
              <Center overflowX={"scroll"}>
                <Text fontSize={"3xl"}>
                  {playerProfile?.tournamentsParticipated}
                </Text>
              </Center>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem colSpan={2} rowSpan={1}>
          <Card shadow={"lg"} bg={"lightpink"} h="100%">
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"gray.600"} textAlign={"center"}>
                  {"Attendance"}
                </Heading>
              </Center>
              <VStack>
                <Center>
                  <Text
                    textAlign={"center"}
                    fontSize={"3xl"}
                    color={"green.900"}
                  >
                    {Math.round(playerProfile?.attendaceSummary ?? 0)}%
                  </Text>
                </Center>
              </VStack>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem colSpan={2} rowSpan={1}>
          <Card shadow={"lg"} bg={"lightcoral"} h="100%">
            <CardHeader>
              <Center>
                <Heading size={"xs"} color={"gray.600"} textAlign={"center"}>
                  {"Days missed"}
                </Heading>
              </Center>
              <VStack>
                <Center>
                  <Text
                    textAlign={"center"}
                    fontSize={"3xl"}
                    color={"green.900"}
                  >
                    {(playerProfile?.noOfDaysSick ?? 0) +
                      (playerProfile?.noOfDaysInjured ?? 0)}
                  </Text>
                </Center>
              </VStack>
            </CardHeader>
          </Card>
        </GridItem>
      </SimpleGrid>
    </VStack>
  );
};

export const PlayerProfileWithFetch = ({ userId }: { userId?: string }) => {
  const { data, isLoading, isFetching, isError, error } = useQuery(
    getPlayerProfile,
    { playerId: userId },
  );

  if (isLoading || isFetching) return <div>Loading</div>;
  if (isError)
    return (
      <Text
        as="h3"
        py={2}
        textAlign="center"
        fontWeight="bold"
        textTransform="uppercase"
        color="red.500"
        letterSpacing={1}
      >
        An error has occurred: {error?.code}{" "}
      </Text>
    );

  return <PlayerProfileCard playerProfile={data?.playerProfile} />;
};

export const PlayerProfileWrapper = ({
  userId,
  children,
}: {
  userId?: string;
  children?: React.ReactNode;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Player Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PlayerProfileWithFetch userId={userId} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box onClick={onOpen}>{children}</Box>
    </>
  );
};
