import { BrowserRouter } from "react-router-dom";
import Screens from "./Screens";
import { AuthProvider } from "hooks";
import { LiveUpdate } from "@capawesome/capacitor-live-update";
import { useEffect, useState } from "react";
import { Loading } from "components/Loading";

const sync = async () => {
  const nextBundleId = await LiveUpdate.sync();
  if (nextBundleId.nextBundleId) {
    await LiveUpdate.reload();
  }
};

export const Main = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    sync()
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading text="Updating App" />;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Screens />
      </AuthProvider>
    </BrowserRouter>
  );
};
