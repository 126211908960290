// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file notification/v1/notification_api.proto (package notification.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetNotificationsCountRequest, GetNotificationsCountResponse, ListNotificationsRequest, ListNotificationsResponse, ReadAllNotificationRequest, ReadAllNotificationResponse, ReadNotificationRequest, ReadNotificationResponse } from "./notification_api_pb.js";

/**
 * @generated from rpc notification.v1.NotificationAPI.ListNotifications
 */
export const listNotifications = {
  localName: "listNotifications",
  name: "ListNotifications",
  kind: MethodKind.Unary,
  I: ListNotificationsRequest,
  O: ListNotificationsResponse,
  service: {
    typeName: "notification.v1.NotificationAPI"
  }
} as const;

/**
 * @generated from rpc notification.v1.NotificationAPI.GetNotificationsCount
 */
export const getNotificationsCount = {
  localName: "getNotificationsCount",
  name: "GetNotificationsCount",
  kind: MethodKind.Unary,
  I: GetNotificationsCountRequest,
  O: GetNotificationsCountResponse,
  service: {
    typeName: "notification.v1.NotificationAPI"
  }
} as const;

/**
 * @generated from rpc notification.v1.NotificationAPI.ReadNotification
 */
export const readNotification = {
  localName: "readNotification",
  name: "ReadNotification",
  kind: MethodKind.Unary,
  I: ReadNotificationRequest,
  O: ReadNotificationResponse,
  service: {
    typeName: "notification.v1.NotificationAPI"
  }
} as const;

/**
 * @generated from rpc notification.v1.NotificationAPI.ReadAllNotification
 */
export const readAllNotification = {
  localName: "readAllNotification",
  name: "ReadAllNotification",
  kind: MethodKind.Unary,
  I: ReadAllNotificationRequest,
  O: ReadAllNotificationResponse,
  service: {
    typeName: "notification.v1.NotificationAPI"
  }
} as const;
