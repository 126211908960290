// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file fee/v1/fee_api.proto (package fee.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Fee } from "./fee_pb.js";
import { User } from "../../base/v1/user_pb.js";
import { Room } from "../../room/v1/room_pb.js";

/**
 * @generated from message fee.v1.UserFee
 */
export const UserFee = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.UserFee",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "is_paid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message fee.v1.MarkFeesRequest
 */
export const MarkFeesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.MarkFeesRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_fees", kind: "message", T: UserFee, repeated: true },
    { no: 3, name: "bill_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "bill_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "paid_on", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "is_late", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message fee.v1.MarkFeesResponse
 */
export const MarkFeesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.MarkFeesResponse",
  [],
);

/**
 * @generated from message fee.v1.UserMarkUnmarkFee
 */
export const UserMarkUnmarkFee = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.UserMarkUnmarkFee",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bill_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "bill_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "is_paid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "is_late", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "paid_on", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message fee.v1.MarkAndUnmarkFeesRequest
 */
export const MarkAndUnmarkFeesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.MarkAndUnmarkFeesRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_fees", kind: "message", T: UserMarkUnmarkFee, repeated: true },
  ],
);

/**
 * @generated from message fee.v1.MarkAndUnmarkFeesResponse
 */
export const MarkAndUnmarkFeesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.MarkAndUnmarkFeesResponse",
  [],
);

/**
 * @generated from message fee.v1.ListFeesRequest
 */
export const ListFeesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.ListFeesRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message fee.v1.ListFeesResponse
 */
export const ListFeesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.ListFeesResponse",
  () => [
    { no: 1, name: "fees", kind: "message", T: Fee, repeated: true },
  ],
);

/**
 * @generated from message fee.v1.ListFeesByBillingCycleRequest
 */
export const ListFeesByBillingCycleRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.ListFeesByBillingCycleRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bill_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "bill_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message fee.v1.ListFeesByBillingCycleResponse
 */
export const ListFeesByBillingCycleResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.ListFeesByBillingCycleResponse",
  () => [
    { no: 1, name: "fees", kind: "message", T: Fee, repeated: true },
  ],
);

/**
 * @generated from message fee.v1.AggregateFeesAnalysisRequest
 */
export const AggregateFeesAnalysisRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.AggregateFeesAnalysisRequest",
  () => [
    { no: 1, name: "bill_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "bill_months", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * @generated from message fee.v1.BatchFeesSummary
 */
export const BatchFeesSummary = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.BatchFeesSummary",
  () => [
    { no: 1, name: "batch_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "total_fees_paid", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message fee.v1.DefaulterSummary
 */
export const DefaulterSummary = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.DefaulterSummary",
  () => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "room", kind: "message", T: Room },
    { no: 3, name: "last_paid_on", kind: "message", T: Timestamp, opt: true },
    { no: 4, name: "months_unpaid", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "total_fees_due", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message fee.v1.AggregateFeesAnalysisResponse
 */
export const AggregateFeesAnalysisResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.AggregateFeesAnalysisResponse",
  () => [
    { no: 1, name: "total_fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "total_fees_paid", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "defaulter_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "batch_fees_summaries", kind: "message", T: BatchFeesSummary, repeated: true },
    { no: 5, name: "defaulter_summaries", kind: "message", T: DefaulterSummary, repeated: true },
  ],
);

/**
 * @generated from message fee.v1.AggregateFeesAnalysisForRoomRequest
 */
export const AggregateFeesAnalysisForRoomRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.AggregateFeesAnalysisForRoomRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bill_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "bill_months", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * @generated from message fee.v1.AggregateFeesAnalysisForRoomResponse
 */
export const AggregateFeesAnalysisForRoomResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.AggregateFeesAnalysisForRoomResponse",
  () => [
    { no: 1, name: "room", kind: "message", T: Room },
    { no: 2, name: "total_fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "total_fees_paid", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "defaulter_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "defaulter_summaries", kind: "message", T: DefaulterSummary, repeated: true },
  ],
);

