import { NotificationType } from "proto/base/v1/notifcation_pb";
import { Field } from "proto/notification/v1/notification_pb";

export const findFieldValue = (
  notification: Field[],
  field: string,
): string => {
  return notification.find((n) => n.name === field)?.value ?? "";
};

export const fieldsToObject = (
  notification: Field[],
): Record<string, string> => {
  const obj: Record<string, string> = {};
  notification.forEach((field) => {
    obj[field.name] = field.value;
  });
  return obj;
};

export const linkForNotification = (
  notificationType: NotificationType,
  fields: Record<string, string>,
): string => {
  switch (notificationType) {
    case NotificationType.HOLIDAY_ADDED:
    case NotificationType.HOLIDAY_REMOVED:
      return `/my-academy-notice-board/${fields.room_id}?tab=1`;
    case NotificationType.SESSION_ADDED:
    case NotificationType.SESSION_REMOVED:
      return `/my-academy-notice-board/${fields.room_id}?tab=2`;

    case NotificationType.BILLING_CYCLE_DUE:
      return `/manage-fees/${fields.room_id}`;

    case NotificationType.BOARD_ADDED:
      return `/board/${fields.board_id}?roomId=${fields.room_id}`;

    case NotificationType.GUIDANCE_ADDED:
      return `/my-chat/${fields.from_user_id}`;

    case NotificationType.BATCH_NOTICE:
      return `/my-academy-notice-board/${fields.room_id}`;

    case NotificationType.DIARY_SHARED_BY_PLAYER:
      return `/student-diary/${fields.from_user_id}`;

    case NotificationType.TOURNAMENT_ADDED:
    case NotificationType.TOURNAMENT_UPDATED:
    case NotificationType.TOURNAMENT_DELETED:
      return `/tournaments`;

    default:
      return "";
  }
};

export const canReadNotification = (
  notificationType: NotificationType,
): boolean => {
  switch (notificationType) {
    case NotificationType.HOLIDAY_ADDED:
    case NotificationType.HOLIDAY_REMOVED:
    case NotificationType.SESSION_ADDED:
    case NotificationType.SESSION_REMOVED:
    case NotificationType.BILLING_CYCLE_DUE:
    case NotificationType.BOARD_ADDED:
    case NotificationType.GUIDANCE_ADDED:
    case NotificationType.BATCH_NOTICE:
    case NotificationType.DIARY_SHARED_BY_PLAYER:
    case NotificationType.TOURNAMENT_ADDED:
    case NotificationType.TOURNAMENT_DELETED:
    case NotificationType.TOURNAMENT_UPDATED:
      return true;

    default:
      return false;
  }
};

export const ctaForNotification = (
  notificationType: NotificationType,
  fields: Record<string, string>,
): string => {
  switch (notificationType) {
    case NotificationType.HOLIDAY_ADDED:
    case NotificationType.HOLIDAY_REMOVED:
      return "View Holiday Board";
    case NotificationType.SESSION_ADDED:
    case NotificationType.SESSION_REMOVED:
      return "View Session Board";

    case NotificationType.BILLING_CYCLE_DUE:
      return "View Fees";

    case NotificationType.BOARD_ADDED:
      return "View Board";

    case NotificationType.GUIDANCE_ADDED:
      return "View Guidance";

    case NotificationType.BATCH_NOTICE:
      return "View Update";

    case NotificationType.DIARY_SHARED_BY_PLAYER:
      return "View Diary";

    case NotificationType.TOURNAMENT_ADDED:
    case NotificationType.TOURNAMENT_UPDATED:
    case NotificationType.TOURNAMENT_DELETED:
      return "View Tournament";

    default:
      return "";
  }
};
