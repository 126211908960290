// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file fee/v1/fee_api.proto (package fee.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { AggregateFeesAnalysisForRoomRequest, AggregateFeesAnalysisForRoomResponse, AggregateFeesAnalysisRequest, AggregateFeesAnalysisResponse, ListFeesByBillingCycleRequest, ListFeesByBillingCycleResponse, ListFeesRequest, ListFeesResponse, MarkAndUnmarkFeesRequest, MarkAndUnmarkFeesResponse, MarkFeesRequest, MarkFeesResponse } from "./fee_api_pb.js";

/**
 * @generated from rpc fee.v1.FeeAPI.MarkFees
 */
export const markFees = {
  localName: "markFees",
  name: "MarkFees",
  kind: MethodKind.Unary,
  I: MarkFeesRequest,
  O: MarkFeesResponse,
  service: {
    typeName: "fee.v1.FeeAPI"
  }
} as const;

/**
 * @generated from rpc fee.v1.FeeAPI.MarkAndUnmarkFees
 */
export const markAndUnmarkFees = {
  localName: "markAndUnmarkFees",
  name: "MarkAndUnmarkFees",
  kind: MethodKind.Unary,
  I: MarkAndUnmarkFeesRequest,
  O: MarkAndUnmarkFeesResponse,
  service: {
    typeName: "fee.v1.FeeAPI"
  }
} as const;

/**
 * @generated from rpc fee.v1.FeeAPI.ListFees
 */
export const listFees = {
  localName: "listFees",
  name: "ListFees",
  kind: MethodKind.Unary,
  I: ListFeesRequest,
  O: ListFeesResponse,
  service: {
    typeName: "fee.v1.FeeAPI"
  }
} as const;

/**
 * @generated from rpc fee.v1.FeeAPI.ListFeesByBillingCycle
 */
export const listFeesByBillingCycle = {
  localName: "listFeesByBillingCycle",
  name: "ListFeesByBillingCycle",
  kind: MethodKind.Unary,
  I: ListFeesByBillingCycleRequest,
  O: ListFeesByBillingCycleResponse,
  service: {
    typeName: "fee.v1.FeeAPI"
  }
} as const;

/**
 * @generated from rpc fee.v1.FeeAPI.AggregateFeesAnalysis
 */
export const aggregateFeesAnalysis = {
  localName: "aggregateFeesAnalysis",
  name: "AggregateFeesAnalysis",
  kind: MethodKind.Unary,
  I: AggregateFeesAnalysisRequest,
  O: AggregateFeesAnalysisResponse,
  service: {
    typeName: "fee.v1.FeeAPI"
  }
} as const;

/**
 * @generated from rpc fee.v1.FeeAPI.AggregateFeesAnalysisForRoom
 */
export const aggregateFeesAnalysisForRoom = {
  localName: "aggregateFeesAnalysisForRoom",
  name: "AggregateFeesAnalysisForRoom",
  kind: MethodKind.Unary,
  I: AggregateFeesAnalysisForRoomRequest,
  O: AggregateFeesAnalysisForRoomResponse,
  service: {
    typeName: "fee.v1.FeeAPI"
  }
} as const;
