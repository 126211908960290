// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file base/v1/notifcation.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum base.v1.NotificationType
 */
export const NotificationType = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.NotificationType",
  [
    {no: 0, name: "NOTIFICATION_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "NOTIFICATION_TYPE_HOLIDAY_ADDED", localName: "HOLIDAY_ADDED"},
    {no: 2, name: "NOTIFICATION_TYPE_HOLIDAY_REMOVED", localName: "HOLIDAY_REMOVED"},
    {no: 3, name: "NOTIFICATION_TYPE_SESSION_ADDED", localName: "SESSION_ADDED"},
    {no: 4, name: "NOTIFICATION_TYPE_SESSION_REMOVED", localName: "SESSION_REMOVED"},
    {no: 5, name: "NOTIFICATION_TYPE_MARK_ATTENDANCE", localName: "MARK_ATTENDANCE"},
    {no: 6, name: "NOTIFICATION_TYPE_ATTENDANCE_MARKED", localName: "ATTENDANCE_MARKED"},
    {no: 7, name: "NOTIFICATION_TYPE_BOARD_ADDED", localName: "BOARD_ADDED"},
    {no: 8, name: "NOTIFICATION_TYPE_GUIDANCE_ADDED", localName: "GUIDANCE_ADDED"},
    {no: 9, name: "NOTIFICATION_TYPE_DIARY_SHARED_TO_COACH", localName: "DIARY_SHARED_TO_COACH"},
    {no: 10, name: "NOTIFICATION_TYPE_DIARY_SHARED_BY_PLAYER", localName: "DIARY_SHARED_BY_PLAYER"},
    {no: 11, name: "NOTIFICATION_TYPE_TOURNAMENT_ADDED", localName: "TOURNAMENT_ADDED"},
    {no: 12, name: "NOTIFICATION_TYPE_TOURNAMENT_UPDATED", localName: "TOURNAMENT_UPDATED"},
    {no: 13, name: "NOTIFICATION_TYPE_TOURNAMENT_DELETED", localName: "TOURNAMENT_DELETED"},
    {no: 14, name: "NOTIFICATION_TYPE_BILLING_CYCLE_DUE", localName: "BILLING_CYCLE_DUE"},
    {no: 15, name: "NOTIFICATION_TYPE_FEES_DUE", localName: "FEES_DUE"},
    {no: 16, name: "NOTIFICATION_TYPE_BATCH_NOTICE", localName: "BATCH_NOTICE"},
  ],
);

