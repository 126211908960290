import {
  Button,
  Card,
  CardHeader,
  Center,
  chakra,
  Heading,
  HStack,
  IconButton,
  Table,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { ConnectError } from "@connectrpc/connect";
// import { useQuery } from '@connectrpc/connect-query';
import { useQuery } from "@tanstack/react-query";
import { roomAPIClient } from "clients";
import { DisplayError, Loading } from "components";
import { format } from "date-fns";
import { formatMoney, protoUserName } from "helpers";
import {
  ListInvitedRoomsResponse,
  ListRoomsResponse,
} from "proto/room/v1/room_api_pb";
import { useCallback } from "react";
import { BsFillGearFill } from "react-icons/bs";
import { CgDetailsMore } from "react-icons/cg";
import { Link } from "react-router-dom";

const useListRooms = (fetchInvited: boolean) => {
  const fetchData = useCallback((fetchInvited: boolean) => {
    return new Promise<ListRoomsResponse | ListInvitedRoomsResponse>(
      async (resolve, reject) => {
        try {
          if (fetchInvited) {
            const data = await roomAPIClient.listInvitedRooms({});
            resolve(data);
          } else {
            const data = await roomAPIClient.listRooms({});
            resolve(data);
          }
        } catch (error) {
          console.log("useListRooms error", error);
          reject(error);
        }
      }
    );
  }, []);

  const { isLoading, isFetching, isError, error, data, refetch } = useQuery<
    ListRoomsResponse | ListInvitedRoomsResponse,
    ConnectError
  >({
    queryKey: ["useListRooms", fetchInvited],
    queryFn: () => fetchData(fetchInvited),
  });

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

export default function ViewFees() {
  const { isLoading, isError, error, data: resourceData } = useListRooms(false);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <DisplayError error={error} />;
  }

  return (
    <chakra.div mb={20}>
      <Center>
        <Heading variant={"h1"} p={4}>
          Fees Management
        </Heading>
      </Center>
      <Button
        colorScheme="facebook"
        variant={"solid"}
        as={Link}
        aria-label=""
        position={"fixed"}
        bottom={5}
        right={5}
        color={"white"}
        borderRadius={"md"}
        to={"/finances"}
        zIndex={1000}
        size={"md"}
      >
        <CgDetailsMore />
        <Text ml={2}>Finances</Text>
      </Button>
      <Center>
        <VStack w="full">
          {resourceData?.rooms.map((item) => {
            return (
              <Card w="90%">
                <CardHeader w="100%">
                  <VStack w="100%" spacing={0}>
                    <Heading size={"xl"}>{item?.name}</Heading>
                    <Table
                      w="90%"
                      size={"sm"}
                      variant={"unstyled"}
                      mt={2}
                      mb={4}
                    >
                      <Tr border={"solid"} borderWidth={"1px"}>
                        <Td border="solid" borderWidth={"1px"} w="50%">
                          Coach
                        </Td>
                        <Td border="solid" borderWidth={"1px"}>
                          {protoUserName(item.owner)}
                        </Td>
                      </Tr>
                      <Tr border={"solid"} borderWidth={"1px"}>
                        <Td border="solid" borderWidth={"1px"}>
                          Members
                        </Td>
                        <Td border="solid" borderWidth={"1px"}>
                          {item.batchStrength}
                        </Td>
                      </Tr>
                      <Tr border={"solid"} borderWidth={"1px"}>
                        <Td border="solid" borderWidth={"1px"}>
                          Default Fees
                        </Td>
                        <Td border="solid" borderWidth={"1px"}>
                          {formatMoney(item.defaultFees)}
                        </Td>
                      </Tr>
                      <Tr border={"solid"} borderWidth={"1px"}>
                        <Td border="solid" borderWidth={"1px"}>
                          Billing Cycle
                        </Td>
                        <Td border="solid" borderWidth={"1px"}>
                          {"Monthly"}
                        </Td>
                      </Tr>
                      <Tr border={"solid"} borderWidth={"1px"}>
                        <Td border="solid" borderWidth={"1px"}>
                          Cycle Date
                        </Td>
                        <Td border="solid" borderWidth={"1px"}>
                          {format(
                            new Date(2025, 0, Number(item.billingCycleDay)),
                            "do"
                          )}
                        </Td>
                      </Tr>
                    </Table>
                  </VStack>

                  <HStack w="100%">
                    <Button
                      as={Link}
                      to={`/manage-fees/${item.id}`}
                      w="full"
                      leftIcon={<BsFillGearFill />}
                      py={6}
                      colorScheme="messenger"
                    >
                      <Text whiteSpace="pre-line">{"Manage"}</Text>
                    </Button>
                    <Button
                      as={Link}
                      to={`/finances/${item.id}`}
                      w="full"
                      colorScheme="yellow"
                      variant={"outline"}
                      leftIcon={<CgDetailsMore />}
                      py={6}
                    >
                      <Text color={"gray"} whiteSpace="pre-line">
                        {"More"}
                      </Text>
                    </Button>
                  </HStack>
                </CardHeader>
              </Card>
            );
          })}
        </VStack>
      </Center>
    </chakra.div>
  );
}
