import {
  Card,
  CardHeader,
  Center,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { DisplayError } from "components/DisplayError";
import { Loading } from "components/Loading";
import { useQuery } from "@connectrpc/connect-query";
import { getQuarter } from "date-fns";
import { formatMoney } from "helpers";
import { aggregateFeesAnalysisForRoom } from "proto/fee/v1/fee_api-FeeAPI_connectquery";
import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { DefaulterList, months, quarters } from "./Finances";

export default function FeesAnalysis() {
  const { id } = useParams<{ id: string }>();
  const [setselectedYear, setSetselectedYear] = useState(() =>
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState(
    () => new Date().getMonth() + 1
  );
  const [selectedQuarter, setSelectedQuarter] = useState(-1);
  const [isQuarterSelected, setIsQuarterSelected] = useState(false);

  const billMonths = useMemo(() => {
    if (isQuarterSelected) {
      if (selectedQuarter === -1) return [new Date().getMonth() + 1];
      return [
        1 + 3 * (selectedQuarter - 1),
        1 + 3 * (selectedQuarter - 1),
        2 + 3 * (selectedQuarter - 1),
      ];
    }
    if (selectedMonth === -1) return [new Date().getMonth() + 1];
    return [selectedMonth];
  }, [isQuarterSelected, selectedMonth, selectedQuarter]);

  const { data, isLoading, isFetching, isError, error } = useQuery(
    aggregateFeesAnalysisForRoom,
    {
      roomId: id ?? "",
      billMonths,
      billYear: setselectedYear,
    }
  );
  const feesColletedPercentage = useMemo(() => {
    if (!data) return 0;
    if (data.totalFees === 0) return 0;
    return Math.floor(
      (Number(data.totalFeesPaid) / Number(data.totalFees)) * 100
    );
  }, [data]);

  const {
    isOpen: defaulterListIsOpen,
    onOpen: defaulterListOnOpen,
    onClose: defaulterListOnClose,
  } = useDisclosure();

  return (
    <>
      <Modal
        isOpen={defaulterListIsOpen}
        onClose={defaulterListOnClose}
        size={"full"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="30%" mt={"10%"}>
          <ModalHeader>
            <VStack>
              <Heading size={"lg"}>Aggregate Defaulter List</Heading>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody h="100%" w="100%">
            <DefaulterList defaulters={data?.defaulterSummaries ?? []} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Center>
        <VStack py={4}>
          <Center>
            <Heading variant={"lg"}>Fees Analysis</Heading>
          </Center>
          <SimpleGrid columns={3} spacingX={2} w="90%">
            <FormControl>
              <FormLabel>Quarter</FormLabel>
              <Select
                w="100%"
                value={selectedQuarter}
                // onFocusCapture={(v) => {
                //   setIsQuarterSelected(true);
                // }}
                onChange={(v) => {
                  if (v.target.value === "-1") {
                    setIsQuarterSelected(false);
                    setSelectedMonth(new Date().getMonth() + 1);
                    setSelectedQuarter(-1);
                  } else {
                    setIsQuarterSelected(true);
                    setSelectedQuarter(Number.parseInt(v.target.value));
                    setSelectedMonth(-1);
                  }
                }}
              >
                <option value={-1}>Select</option>
                {quarters.map((v, idx) => {
                  return <option value={idx + 1}>{v}</option>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Month</FormLabel>
              <Select
                w="100%"
                value={selectedMonth}
                // onFocusCapture={(v) => {
                //   setIsQuarterSelected(false);
                // }}
                onChange={(v) => {
                  if (v.target.value === "-1") {
                    setIsQuarterSelected(true);
                    setSelectedMonth(-1);
                    setSelectedQuarter(getQuarter(new Date()));
                  } else {
                    setIsQuarterSelected(false);
                    setSelectedMonth(Number.parseInt(v.target.value));
                    setSelectedQuarter(-1);
                  }
                }}
              >
                <option value={-1}>Select</option>
                {months.map((v, idx) => {
                  return <option value={idx + 1}>{v}</option>;
                })}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Year</FormLabel>
              <Select
                w="100%"
                value={setselectedYear}
                onChange={(v) => {
                  setSetselectedYear(Number.parseInt(v.target.value));
                }}
              >
                {Array.from(
                  { length: 10 },
                  (_, i) => new Date().getFullYear() - i
                )
                  .filter((v) => v > 2020)
                  .map((v) => {
                    return <option value={v}>{v}</option>;
                  })}
              </Select>
            </FormControl>
          </SimpleGrid>

          {isLoading || isFetching ? (
            <Loading />
          ) : isError ? (
            <DisplayError error={error} />
          ) : (
            <>
              <Text fontSize={"small"}>
                Analysis for{" "}
                {isQuarterSelected
                  ? quarters[selectedQuarter - 1]
                  : months[selectedMonth - 1]}{" "}
                {setselectedYear}
              </Text>
              <SimpleGrid columns={4} w="90%" pt={2} spacing={2}>
                <Card shadow={"lg"} bg="blue.100">
                  <CardHeader>
                    <Center w="100%">
                      <Heading size={"2xl"} color={"blue"} textAlign={"center"}>
                        {feesColletedPercentage}%
                      </Heading>
                    </Center>
                    <Center>
                      <Text
                        textAlign={"center"}
                        // whiteSpace={"pre-line"}
                        fontSize={"sm"}
                      >
                        {"Fees \n collected"}
                      </Text>
                    </Center>
                  </CardHeader>
                </Card>
                <GridItem colSpan={3} h="100%">
                  <Card shadow={"lg"} bg="purple.200" h="100%">
                    <CardHeader>
                      <Center w="100%">
                        <Heading
                          size={"2xl"}
                          color={"purple.900"}
                          textAlign={"center"}
                        >
                          {formatMoney(data?.totalFeesPaid)}
                        </Heading>
                      </Center>
                      <Center>
                        <Text
                          textAlign={"center"}
                          whiteSpace={"pre-line"}
                          fontSize={"sm"}
                        >
                          {"Fees collection"}
                        </Text>
                      </Center>
                    </CardHeader>
                  </Card>
                </GridItem>

                <GridItem colSpan={4}>
                  <Card
                    shadow={"lg"}
                    bg="red.200"
                    onClick={() => defaulterListOnOpen()}
                  >
                    <CardHeader>
                      <Center w="100%">
                        <Heading
                          size={"4xl"}
                          color={"red"}
                          textAlign={"center"}
                        >
                          {data?.defaulterCount}
                        </Heading>
                      </Center>
                      <Center>
                        <Text
                          textAlign={"center"}
                          // whiteSpace={"pre-line"}
                          fontSize={"sm"}
                        >
                          {"Defaulters"}
                        </Text>
                      </Center>
                    </CardHeader>
                  </Card>
                </GridItem>
              </SimpleGrid>
            </>
          )}
        </VStack>
      </Center>
    </>
  );
}
