import {
  Button,
  Card,
  CardHeader,
  Center,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  SimpleGrid,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  Textarea,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Loading, DisplayError } from "components";
import { useMutation, useQuery } from "@connectrpc/connect-query";
import { RangeDatepicker, SingleDatepicker } from "chakra-dayzed-datepicker";
import { aggregateFeesAnalysis } from "proto/fee/v1/fee_api-FeeAPI_connectquery";
import { useMemo, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import { formatMoney, protoUserName } from "helpers";
import {
  addMonths,
  endOfDay,
  endOfMonth,
  format,
  getQuarter,
  isSameMonth,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { DefaulterSummary } from "proto/fee/v1/fee_api_pb";
import { ExpenseType } from "proto/base/v1/expense_pb";
import {
  addExpense,
  listExpensesByTypes,
} from "proto/expense/v1/expense_api-ExpenseAPI_connectquery";
import { Controller, useForm } from "react-hook-form";
import { Timestamp } from "@bufbuild/protobuf";
import { Expense } from "proto/expense/v1/expense_pb";

export const quarters = ["Q1", "Q2", "Q3", "Q4"];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const DefaulterList = ({
  defaulters: rawDefaulters,
}: {
  defaulters: DefaulterSummary[];
}) => {
  const defaulters = useMemo(() => {
    return rawDefaulters.sort((a, b) => b.monthsUnpaid - a.monthsUnpaid);
  }, [rawDefaulters]);
  const totalAmountDue = useMemo(() => {
    return defaulters.reduce((acc, defaulter) => {
      return acc + Number(defaulter.totalFeesDue);
    }, 0);
  }, [defaulters]);
  const totalMonthsUnpaid = useMemo(() => {
    return defaulters.reduce((acc, defaulter) => {
      return acc + defaulter.monthsUnpaid;
    }, 0);
  }, [defaulters]);

  return (
    <>
      <chakra.div minH="60vh">
        <SimpleGrid columns={20} py={4} overflow={"scroll"} fontSize={"sm"}>
          <GridItem
            rowSpan={1}
            colSpan={6}
            border={"solid"}
            borderWidth={"0.5px"}
          >
            <Flex px={1} h="100%" alignItems={"flex-end"}>
              <Text textAlign="center" w="100%">
                Name
              </Text>
            </Flex>
          </GridItem>
          <GridItem
            colSpan={4}
            textAlign={"center"}
            border={"solid"}
            borderLeft={"none"}
            borderWidth={"0.5px"}
          >
            <Flex px={1} h="100%" alignItems={"flex-end"}>
              <Text>Batch Name</Text>
            </Flex>
          </GridItem>
          <GridItem
            colSpan={3}
            textAlign={"center"}
            border={"solid"}
            borderLeft={"none"}
            borderWidth={"0.5px"}
          >
            <Flex px={1} h="100%" alignItems={"flex-end"}>
              <Text>Last Paid Date</Text>
            </Flex>
          </GridItem>
          <GridItem
            colSpan={3}
            textAlign={"center"}
            border={"solid"}
            borderLeft={"none"}
            borderWidth={"0.5px"}
          >
            <Flex px={1} h="100%" alignItems={"flex-end"}>
              <Text>Months Unpaid</Text>
            </Flex>
          </GridItem>
          <GridItem
            colSpan={4}
            textAlign={"center"}
            border={"solid"}
            borderLeft={"none"}
            borderWidth={"0.5px"}
          >
            <Flex px={1} h="100%" alignItems={"flex-end"}>
              <Text>Amount Due</Text>
            </Flex>
          </GridItem>
          {defaulters.map((defaulter) => {
            return (
              <>
                <GridItem
                  fontSize={"sm"}
                  colSpan={6}
                  textAlign={"center"}
                  border={"solid"}
                  borderTop={"none"}
                  borderWidth={"0.5px"}
                >
                  {protoUserName(defaulter.user)}
                </GridItem>
                <GridItem
                  fontSize={"sm"}
                  colSpan={4}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderTop={"none"}
                  borderWidth={"0.5px"}
                >
                  {defaulter.room?.name}
                </GridItem>
                <GridItem
                  fontSize={"sm"}
                  colSpan={3}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderTop={"none"}
                  borderWidth={"0.5px"}
                >
                  {defaulter.lastPaidOn
                    ? format(defaulter.lastPaidOn.toDate(), "dd MMM yyyy")
                    : ""}
                </GridItem>
                <GridItem
                  fontSize={"sm"}
                  colSpan={3}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderTop={"none"}
                  borderWidth={"0.5px"}
                >
                  {defaulter.monthsUnpaid}
                </GridItem>
                <GridItem
                  colSpan={4}
                  fontSize={"sm"}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderTop={"none"}
                  borderWidth={"0.5px"}
                >
                  {formatMoney(defaulter.totalFeesDue)}
                </GridItem>
              </>
            );
          })}
        </SimpleGrid>
      </chakra.div>
      <SimpleGrid columns={20} py={4} maxH="60vh" overflow={"scroll"}>
        <GridItem
          rowSpan={1}
          colSpan={10}
          border={"solid"}
          borderWidth={"2px"}
          h="100%"
          pl={1}
        >
          <Text fontSize={"lg"} fontWeight={"bold"}>
            Total
          </Text>
        </GridItem>

        <GridItem
          rowSpan={1}
          colSpan={5}
          border={"solid"}
          borderWidth={"2px"}
          borderLeft={"none"}
          h="100%"
          pl={1}
        >
          <Text fontSize={"lg"} fontWeight={"bold"} textAlign={"center"}>
            {totalMonthsUnpaid}
          </Text>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={5}
          border={"solid"}
          borderWidth={"2px"}
          borderLeft={"none"}
          h="100%"
          pl={1}
        >
          <Text fontSize={"lg"} fontWeight={"bold"} textAlign={"center"}>
            ₹ {totalAmountDue}
          </Text>
        </GridItem>
      </SimpleGrid>
      {/* <Button
        // zIndex={1000}
        position="fixed"
        bottom={5}
        right={5}
        colorScheme="green"
        onClick={() => {
          // defaulterListOnClose();
        }}
      >
        <HStack>
          <BsSave2 />
          <Text ml={1}>Mark Paid Fees</Text>
        </HStack>
      </Button> */}
    </>
  );
};

const CollectionTab = () => {
  const [setselectedYear, setSetselectedYear] = useState(() =>
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState(
    () => new Date().getMonth() + 1
  );
  const [selectedQuarter, setSelectedQuarter] = useState(-1);
  const [isQuarterSelected, setIsQuarterSelected] = useState(false);

  const billMonths = useMemo(() => {
    if (isQuarterSelected) {
      if (selectedQuarter === -1) return [new Date().getMonth() + 1];
      return [
        1 + 3 * (selectedQuarter - 1),
        1 + 3 * (selectedQuarter - 1),
        2 + 3 * (selectedQuarter - 1),
      ];
    }
    if (selectedMonth === -1) return [new Date().getMonth() + 1];
    return [selectedMonth];
  }, [isQuarterSelected, selectedMonth, selectedQuarter]);

  const { data, isLoading, isFetching, isError, error } = useQuery(
    aggregateFeesAnalysis,
    {
      billMonths,
      billYear: setselectedYear,
    }
  );
  const feesColletedPercentage = useMemo(() => {
    if (!data) return 0;
    if (data.totalFees === 0) return 0;
    return Math.floor(
      (Number(data.totalFeesPaid) / Number(data.totalFees)) * 100
    );
  }, [data]);

  const {
    isOpen: defaulterListIsOpen,
    onOpen: defaulterListOnOpen,
    onClose: defaulterListOnClose,
  } = useDisclosure();

  return (
    <>
      <Modal
        isOpen={defaulterListIsOpen}
        onClose={defaulterListOnClose}
        size={"full"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="30%" mt={"10%"}>
          <ModalHeader>
            <VStack>
              <Heading size={"lg"}>Aggregate Defaulter List</Heading>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody h="100%" w="100%">
            <DefaulterList defaulters={data?.defaulterSummaries ?? []} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Center>
        <VStack>
          <SimpleGrid columns={3} spacingX={2} w="90%">
            <FormControl>
              <FormLabel>Quarter</FormLabel>
              <Select
                w="100%"
                value={selectedQuarter}
                // onFocusCapture={(v) => {
                //   setIsQuarterSelected(true);
                // }}
                onChange={(v) => {
                  if (v.target.value === "-1") {
                    setIsQuarterSelected(false);
                    setSelectedMonth(new Date().getMonth() + 1);
                    setSelectedQuarter(-1);
                  } else {
                    setIsQuarterSelected(true);
                    setSelectedQuarter(Number.parseInt(v.target.value));
                    setSelectedMonth(-1);
                  }
                }}
              >
                <option value={-1}>Select</option>
                {quarters.map((v, idx) => {
                  return <option value={idx + 1}>{v}</option>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Month</FormLabel>
              <Select
                w="100%"
                value={selectedMonth}
                // onFocusCapture={(v) => {
                //   setIsQuarterSelected(false);
                // }}
                onChange={(v) => {
                  if (v.target.value === "-1") {
                    setIsQuarterSelected(true);
                    setSelectedMonth(-1);
                    setSelectedQuarter(getQuarter(new Date()));
                  } else {
                    setIsQuarterSelected(false);
                    setSelectedMonth(Number.parseInt(v.target.value));
                    setSelectedQuarter(-1);
                  }
                }}
              >
                <option value={-1}>Select</option>
                {months.map((v, idx) => {
                  return <option value={idx + 1}>{v}</option>;
                })}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Year</FormLabel>
              <Select
                w="100%"
                value={setselectedYear}
                onChange={(v) => {
                  setSetselectedYear(Number.parseInt(v.target.value));
                }}
              >
                {Array.from(
                  { length: 10 },
                  (_, i) => new Date().getFullYear() - i
                )
                  .filter((v) => v > 2020)
                  .map((v) => {
                    return <option value={v}>{v}</option>;
                  })}
              </Select>
            </FormControl>
          </SimpleGrid>

          {isLoading || isFetching ? (
            <Loading />
          ) : isError ? (
            <DisplayError error={error} />
          ) : (
            <>
              <Text fontSize={"small"}>
                Analysis for{" "}
                {isQuarterSelected
                  ? quarters[selectedQuarter - 1]
                  : months[selectedMonth - 1]}{" "}
                {setselectedYear}
              </Text>
              <SimpleGrid columns={4} w="90%" pt={2} spacing={2}>
                <Card shadow={"lg"} bg="blue.100">
                  <CardHeader>
                    <Center w="100%">
                      <Heading size={"2xl"} color={"blue"} textAlign={"center"}>
                        {feesColletedPercentage}%
                      </Heading>
                    </Center>
                    <Center>
                      <Text
                        textAlign={"center"}
                        // whiteSpace={"pre-line"}
                        fontSize={"sm"}
                      >
                        {"Fees \n collected"}
                      </Text>
                    </Center>
                  </CardHeader>
                </Card>
                <GridItem colSpan={3} h="100%">
                  <Card shadow={"lg"} bg="purple.200" h="100%">
                    <CardHeader>
                      <Center w="100%">
                        <Heading
                          size={"2xl"}
                          color={"purple.900"}
                          textAlign={"center"}
                        >
                          {formatMoney(data?.totalFeesPaid)}
                        </Heading>
                      </Center>
                      <Center>
                        <Text
                          textAlign={"center"}
                          whiteSpace={"pre-line"}
                          fontSize={"sm"}
                        >
                          {"Fees collection"}
                        </Text>
                      </Center>
                    </CardHeader>
                  </Card>
                </GridItem>
                <GridItem colSpan={4} rowSpan={2}>
                  <Card shadow={"lg"} h="100%" maxH="40vh" overflow={"scroll"}>
                    <CardHeader>
                      <Center>
                        <Heading size={"md"}>Batch Wise Collection</Heading>
                      </Center>
                      <Table variant="simple" fontSize={"sm"} size={"xs"}>
                        <Tr>
                          <Td>Name</Td>
                          <Td>Exp.</Td>
                          <Td>Act.</Td>
                          <Td>%</Td>
                        </Tr>
                        {data?.batchFeesSummaries.map((summary) => {
                          return (
                            <>
                              <Tr>
                                <Td>{summary.batchName}</Td>
                                <Td>{formatMoney(summary.totalFees)}</Td>
                                <Td>{formatMoney(summary.totalFeesPaid)}</Td>
                                <Td>
                                  {Math.floor(
                                    (Number(summary.totalFeesPaid) /
                                      Number(summary.totalFees)) *
                                      100
                                  )}
                                </Td>
                              </Tr>
                            </>
                          );
                        })}
                      </Table>
                    </CardHeader>
                  </Card>
                </GridItem>
                <GridItem colSpan={4}>
                  <Card
                    shadow={"lg"}
                    bg="red.200"
                    onClick={() => defaulterListOnOpen()}
                  >
                    <CardHeader>
                      <Center w="100%">
                        <Heading
                          size={"4xl"}
                          color={"red"}
                          textAlign={"center"}
                        >
                          {data?.defaulterCount}
                        </Heading>
                      </Center>
                      <Center>
                        <Text
                          textAlign={"center"}
                          // whiteSpace={"pre-line"}
                          fontSize={"sm"}
                        >
                          {"Defaulters"}
                        </Text>
                      </Center>
                    </CardHeader>
                  </Card>
                </GridItem>
              </SimpleGrid>
            </>
          )}
        </VStack>
      </Center>
    </>
  );
};

interface ExpenseForm {
  description: string;
  category: string;
  type: ExpenseType;
  amount: number;
  date: Date;
}

interface ExpensesByMonth {
  month: Date;
  expenses: Expense[];
}

interface ExpensesByCategory {
  category: string;
  expenses: Expense[];
  totalAmount: number;
}

const ExpenseTab = ({ type }: { type: ExpenseType }) => {
  const from = useMemo(
    () => Timestamp.fromDate(addMonths(startOfMonth(new Date()), -2)),
    []
  );
  const to = useMemo(() => Timestamp.fromDate(endOfMonth(new Date())), []);

  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    listExpensesByTypes,
    {
      types: [type],
      from,
      to,
    }
  );
  const expensesByMonths = useMemo<ExpensesByMonth[]>(() => {
    if (!data) return [];
    const expensesByMonths: ExpensesByMonth[] = [];

    data.expenses.forEach((expense) => {
      const month = expense?.date?.toDate() ?? new Date();
      const expenseByMonth = expensesByMonths.findIndex((v) =>
        isSameMonth(v.month, month)
      );
      if (expenseByMonth >= 0) {
        expensesByMonths[expenseByMonth].expenses.push(expense);
      } else {
        expensesByMonths.push({
          month,
          expenses: [expense],
        });
      }
    });

    return expensesByMonths.sort(
      (a, b) => b.month.getTime() - a.month.getTime()
    );
  }, [data]);

  const {
    isOpen: addIncomeIsOpen,
    onOpen: addIncomeOnOpen,
    onClose: addIncomeOnClose,
  } = useDisclosure();

  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm<ExpenseForm>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      amount: 0,
      category: "",
      date: new Date(),
      description: "",
      type: ExpenseType.DEBIT,
    },
  });

  const { mutate, isPending } = useMutation(addExpense, {
    onSuccess: () => {
      toast({
        title: type === ExpenseType.CREDIT ? "Income added!" : "Expense added!",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      reset();
      addIncomeOnClose();
      refetch();
    },
    onError: () => {
      toast({
        title:
          "Error adding " +
          (type === ExpenseType.CREDIT ? "income" : "expense"),
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    },
  });

  if (isLoading || isFetching) return <Loading />;
  if (isError) return <DisplayError error={error} />;

  return (
    <Center>
      <Modal
        isOpen={addIncomeIsOpen}
        onClose={addIncomeOnClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="30%" w="90%" pb={4}>
          <ModalHeader>
            <VStack>
              <Heading size={"lg"}>
                Add {type === ExpenseType.CREDIT ? "Income" : "Expense"}
              </Heading>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <chakra.form
              onSubmit={handleSubmit((data) => {
                mutate({
                  amount: Number.parseFloat(data.amount.toString()),
                  category: data.category,
                  description: data.description,
                  date: Timestamp.fromDate(data.date),
                  title: data.category,
                  type,
                });
              })}
            >
              <VStack>
                <HStack w="100%">
                  <Text>Select Date</Text>
                  {/* <Spacer /> */}
                  <Controller
                    control={control}
                    name="date"
                    render={({ field }) => (
                      <SingleDatepicker
                        name="date"
                        date={field.value}
                        onDateChange={field.onChange}
                        configs={{
                          dateFormat: "dd-MMM-yyyy",
                        }}
                      />
                    )}
                  />
                </HStack>

                <FormControl pt={2}>
                  <FormLabel>Amount</FormLabel>
                  <Controller
                    control={control}
                    name="amount"
                    render={({ field }) => (
                      <InputGroup>
                        <InputLeftAddon>₹</InputLeftAddon>
                        <NumberInput min={0} {...field}>
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </InputGroup>
                    )}
                  />
                </FormControl>

                <FormControl
                  id="category"
                  isInvalid={!!errors?.category}
                  pt={2}
                >
                  <FormLabel>Category</FormLabel>
                  <Input
                    placeholder="Enter Category (Sales, Rent, etc)"
                    {...register("category", { required: "Required" })}
                  />
                  <FormErrorMessage>
                    {errors?.category && errors?.category?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  id="description"
                  isInvalid={!!errors?.description}
                  pt={2}
                >
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    placeholder="Enter Description"
                    {...register("description", { required: "Required" })}
                  />
                  <FormErrorMessage>
                    {errors?.description && errors?.description?.message}
                  </FormErrorMessage>
                </FormControl>
                <Flex w="100%" justifyContent={"flex-end"}>
                  <Button
                    colorScheme="green"
                    size={"md"}
                    type="submit"
                    isLoading={isSubmitting || isPending}
                  >
                    {<FaPlus />} <Text ml={2}>Add</Text>
                  </Button>
                </Flex>
              </VStack>
            </chakra.form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button
        position={"fixed"}
        bottom={5}
        right={5}
        onClick={() => addIncomeOnOpen()}
        colorScheme={type === ExpenseType.CREDIT ? "green" : "red"}
      >
        <HStack>
          <IoAdd />
          <Text ml={1}>
            Add {type === ExpenseType.CREDIT ? "Income" : "Expense"}
          </Text>
        </HStack>
      </Button>
      <VStack>
        <Heading size={"lg"}>
          {type === ExpenseType.CREDIT
            ? "Additonal Income History"
            : "Expense History"}
        </Heading>
        <Center>
          <Flex>
            <SimpleGrid
              columns={10}
              mb={16}
              w="100%"
              maxH={"60vh"}
              overflow={"scroll"}
            >
              <GridItem
                position={"sticky"}
                w="100%"
                px={1}
                colSpan={3}
                border={"solid"}
                borderWidth={"0.5px"}
              >
                <Text textAlign={"center"}>Date</Text>
              </GridItem>
              <GridItem
                position={"static"}
                w="100%"
                colSpan={2}
                textAlign={"center"}
                border={"solid"}
                borderLeft={"none"}
                borderWidth={"0.5px"}
              >
                <Text>Category</Text>
              </GridItem>
              <GridItem
                position={"static"}
                w="100%"
                colSpan={3}
                textAlign={"center"}
                border={"solid"}
                borderLeft={"none"}
                borderWidth={"0.5px"}
              >
                <Text>Description</Text>
              </GridItem>
              <GridItem
                position={"static"}
                w="100%"
                colSpan={2}
                textAlign={"center"}
                border={"solid"}
                borderLeft={"none"}
                borderWidth={"0.5px"}
              >
                <Text>Amount</Text>
              </GridItem>
              {expensesByMonths.map((expense, index) => (
                <>
                  <GridItem
                    mt={index === 0 ? 0 : 2}
                    position={"static"}
                    w="100%"
                    colSpan={10}
                    border={"solid"}
                    borderWidth={"0.5px"}
                    borderTop={"none"}
                    textAlign={"center"}
                    p={2}
                    fontWeight={"bold"}
                    bg={"green.100"}
                  >
                    {format(expense.month, "MMMM yyyy")}
                  </GridItem>
                  {expense.expenses.map((income) => {
                    return (
                      <>
                        <GridItem
                          fontSize={"sm"}
                          colSpan={3}
                          textAlign={"center"}
                          border={"solid"}
                          borderTop={"none"}
                          borderWidth={"0.5px"}
                        >
                          {format(income!.date!.toDate(), "dd-MMM-yyyy")}
                        </GridItem>
                        <GridItem
                          fontSize={"sm"}
                          colSpan={2}
                          textAlign={"center"}
                          border={"solid"}
                          borderLeft={"none"}
                          borderTop={"none"}
                          borderWidth={"0.5px"}
                        >
                          {income.category}
                        </GridItem>
                        <GridItem
                          fontSize={"sm"}
                          colSpan={3}
                          textAlign={"center"}
                          border={"solid"}
                          borderLeft={"none"}
                          borderTop={"none"}
                          borderWidth={"0.5px"}
                        >
                          {income.description}
                        </GridItem>
                        <GridItem
                          fontSize={"sm"}
                          colSpan={2}
                          textAlign={"center"}
                          border={"solid"}
                          borderLeft={"none"}
                          borderTop={"none"}
                          borderWidth={"0.5px"}
                        >
                          {income.amount}
                        </GridItem>
                      </>
                    );
                  })}
                  <GridItem
                    w="100%"
                    colSpan={10}
                    border={"solid"}
                    borderWidth={"0.5px"}
                    borderTop={"none"}
                    textAlign={"right"}
                    fontWeight={"bold"}
                    p={2}
                    bg={"blue.100"}
                  >
                    {format(expense.month, "MMMM yyyy")} Total{" "}
                    {type === ExpenseType.CREDIT ? "Income" : "Expense"} :{" "}
                    {expense.expenses.reduce(
                      (acc, curr) => acc + curr.amount,
                      0
                    )}
                  </GridItem>
                </>
              ))}
            </SimpleGrid>
          </Flex>
        </Center>
      </VStack>
    </Center>
  );
};

const BalanceStatementTab = () => {
  const [selectedDates, setSelectedDates] = useState<Date[]>(() => [
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);

  const from = useMemo(
    () => Timestamp.fromDate(startOfDay(selectedDates[0])),
    [selectedDates]
  );
  const to = useMemo(
    () =>
      Timestamp.fromDate(
        endOfDay(selectedDates.length > 1 ? selectedDates[1] : selectedDates[0])
      ),
    [selectedDates]
  );

  const { data, isLoading, isFetching, isError, error } = useQuery(
    listExpensesByTypes,
    {
      types: [ExpenseType.CREDIT, ExpenseType.DEBIT],
      from,
      to,
    }
  );

  const expensesByCategories = useMemo<ExpensesByCategory[]>(() => {
    if (!data) return [];
    const credits = data.expenses.filter((expense) => {
      return expense.type === ExpenseType.DEBIT;
    });

    const expensesByCategories: ExpensesByCategory[] = [];

    credits.forEach((expense) => {
      const category = expense?.category ?? "Others";
      const expenseByCategory = expensesByCategories.findIndex(
        (v) => v.category === category
      );
      if (expenseByCategory >= 0) {
        expensesByCategories[expenseByCategory].expenses.push(expense);
        expensesByCategories[expenseByCategory].totalAmount +=
          expense.amount * -1;
      } else {
        expensesByCategories.push({
          category,
          expenses: [expense],
          totalAmount: expense.amount * -1,
        });
      }
    });

    expensesByCategories.sort((a, b) => a.category.localeCompare(b.category));

    return expensesByCategories;
  }, [data]);

  const incomeByCategories = useMemo<ExpensesByCategory[]>(() => {
    if (!data) return [];
    const credits = data.expenses.filter((expense) => {
      return expense.type === ExpenseType.CREDIT;
    });

    const expensesByCategories: ExpensesByCategory[] = [];

    credits.forEach((expense) => {
      const category = expense?.category ?? "Others";
      const expenseByCategory = expensesByCategories.findIndex(
        (v) => v.category === category
      );
      if (expenseByCategory >= 0) {
        expensesByCategories[expenseByCategory].expenses.push(expense);
        expensesByCategories[expenseByCategory].totalAmount += expense.amount;
      } else {
        expensesByCategories.push({
          category,
          expenses: [expense],
          totalAmount: expense.amount,
        });
      }
    });

    expensesByCategories.sort((a, b) => a.category.localeCompare(b.category));

    return expensesByCategories;
  }, [data]);

  const totalIncome = useMemo(() => {
    return incomeByCategories.reduce((acc, curr) => {
      return acc + curr.expenses.reduce((acc, curr) => acc + curr.amount, 0);
    }, 0);
  }, [incomeByCategories]);
  const totalExpense = useMemo(() => {
    return expensesByCategories.reduce((acc, curr) => {
      return acc + curr.expenses.reduce((acc, curr) => acc + curr.amount, 0);
    }, 0);
  }, [expensesByCategories]);
  const totalBalance = useMemo(() => {
    return totalIncome + totalExpense;
  }, [totalIncome, totalExpense]);

  if (isLoading || isFetching) return <Loading />;
  if (isError) return <DisplayError error={error} />;

  return (
    <Center>
      <VStack w="100%">
        <Heading size={"lg"}>Balance Statement</Heading>
        <VStack>
          <HStack>
            {/* <Text>Select Dates</Text> */}
            <RangeDatepicker
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
            />
          </HStack>
          <SimpleGrid columns={4} w="100%" pt={2}>
            <GridItem
              bg="blue.100"
              pl={1}
              colSpan={1}
              h="100%"
              border={"solid"}
              borderWidth={"0.5px"}
            >
              <VStack>
                <Text w="100%">Income</Text>
              </VStack>
            </GridItem>
            <GridItem
              bg="blue.100"
              colSpan={3}
              h="100%"
              border={"solid"}
              borderWidth={"0.5px"}
              borderLeft={"none"}
            >
              <SimpleGrid columns={2} w="90%" m={2}>
                {incomeByCategories.map((item, index) => {
                  return (
                    <>
                      <GridItem
                        whiteSpace={"nowrap"}
                        border={"solid"}
                        borderWidth={"0.5px"}
                        borderTop={index === 0 ? "" : "none"}
                      >
                        <Text ml={1}>{item.category}</Text>
                      </GridItem>
                      <GridItem
                        border={"solid"}
                        borderWidth={"0.5px"}
                        borderLeft={"none"}
                        borderTop={index === 0 ? "" : "none"}
                      >
                        <Text textAlign={"right"} mr={1}>
                          {item.totalAmount}
                        </Text>
                      </GridItem>
                    </>
                  );
                })}
              </SimpleGrid>
              <Text textAlign={"right"} mr={2}>
                {totalIncome}
              </Text>
            </GridItem>
            <GridItem
              bg="orange.100"
              pl={1}
              colSpan={1}
              h="100%"
              border={"solid"}
              borderWidth={"0.5px"}
              borderTop={"none"}
            >
              <VStack>
                <Text w="100%">Expenses</Text>
              </VStack>
            </GridItem>
            <GridItem
              bg="orange.100"
              textAlign={"center"}
              colSpan={3}
              h="100%"
              border={"solid"}
              borderWidth={"0.5px"}
              borderTop={"none"}
              borderLeft={"none"}
            >
              <SimpleGrid columns={2} w="90%" m={2}>
                {expensesByCategories.map((item, index) => {
                  return (
                    <>
                      <GridItem
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        border={"solid"}
                        borderWidth={"0.5px"}
                        borderTop={index === 0 ? "" : "none"}
                      >
                        <Text ml={1}>{item.category}</Text>
                      </GridItem>
                      <GridItem
                        border={"solid"}
                        borderWidth={"0.5px"}
                        borderLeft={"none"}
                        borderTop={index === 0 ? "" : "none"}
                      >
                        <Text textAlign={"right"} mr={1}>
                          {item.totalAmount}
                        </Text>
                      </GridItem>
                    </>
                  );
                })}
              </SimpleGrid>
              <Text textAlign={"right"} mr={2}>
                {totalExpense}
              </Text>
            </GridItem>
            <GridItem
              colSpan={1}
              pl={1}
              h="100%"
              border={"solid"}
              borderWidth={"0.5px"}
              borderTop={"none"}
              bg={totalBalance ? "green.100" : "red.100"}
            >
              <VStack>
                <Text w="100%">Total</Text>
              </VStack>
            </GridItem>
            <GridItem
              colSpan={3}
              h="100%"
              border={"solid"}
              borderWidth={"0.5px"}
              borderTop={"none"}
              borderLeft={"none"}
            >
              <Flex
                w="100%"
                justify={"flex-end"}
                pr={2}
                bg={totalBalance ? "green.100" : "red.100"}
              >
                <Text
                  fontWeight={"bold"}
                  color={totalBalance ? "green.700" : "red.700"}
                >
                  {totalBalance ? "+" : "-"}
                </Text>
                <Text
                  ml={1}
                  fontWeight={"bold"}
                  color={totalBalance ? "green.700" : "red.700"}
                >
                  {totalBalance}
                </Text>
              </Flex>
            </GridItem>
          </SimpleGrid>
        </VStack>
      </VStack>
    </Center>
  );
};

export default function AggregatedFeesAnalysis() {
  return (
    <chakra.div>
      <Center py={4}>
        <VStack>
          <Heading variant={"lg"}>Finances</Heading>
          <Tabs variant={"enclosed"} isFitted={true} px={1}>
            <TabList>
              <Tab>Collection</Tab>
              <Tab>Income</Tab>
              <Tab>Expenses</Tab>
              <Tab>Balance</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <CollectionTab />
              </TabPanel>
              <TabPanel>
                <ExpenseTab type={ExpenseType.CREDIT} />
              </TabPanel>
              <TabPanel>
                <ExpenseTab type={ExpenseType.DEBIT} />
              </TabPanel>
              <TabPanel>
                <BalanceStatementTab />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Center>
    </chakra.div>
  );
}
