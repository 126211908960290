// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file notification/v1/notification.proto (package notification.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { NotificationType } from "../../base/v1/notifcation_pb.js";

/**
 * @generated from message notification.v1.Field
 */
export const Field = /*@__PURE__*/ proto3.makeMessageType(
  "notification.v1.Field",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "include_in_data", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message notification.v1.Notification
 */
export const Notification = /*@__PURE__*/ proto3.makeMessageType(
  "notification.v1.Notification",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "notification_type", kind: "enum", T: proto3.getEnumType(NotificationType) },
    { no: 3, name: "created_on", kind: "message", T: Timestamp },
    { no: 4, name: "fields", kind: "message", T: Field, repeated: true },
    { no: 5, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

