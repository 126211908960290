// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file expense/v1/expense_api.proto (package expense.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { AddExpenseRequest, AddExpenseResponse, ListExpensesByTypesRequest, ListExpensesByTypesResponse, ListExpensesRequest, ListExpensesResponse, RemoveExpenseRequest, RemoveExpenseResponse } from "./expense_api_pb.js";

/**
 * @generated from rpc expense.v1.ExpenseAPI.AddExpense
 */
export const addExpense = {
  localName: "addExpense",
  name: "AddExpense",
  kind: MethodKind.Unary,
  I: AddExpenseRequest,
  O: AddExpenseResponse,
  service: {
    typeName: "expense.v1.ExpenseAPI"
  }
} as const;

/**
 * @generated from rpc expense.v1.ExpenseAPI.ListExpenses
 */
export const listExpenses = {
  localName: "listExpenses",
  name: "ListExpenses",
  kind: MethodKind.Unary,
  I: ListExpensesRequest,
  O: ListExpensesResponse,
  service: {
    typeName: "expense.v1.ExpenseAPI"
  }
} as const;

/**
 * @generated from rpc expense.v1.ExpenseAPI.ListExpensesByTypes
 */
export const listExpensesByTypes = {
  localName: "listExpensesByTypes",
  name: "ListExpensesByTypes",
  kind: MethodKind.Unary,
  I: ListExpensesByTypesRequest,
  O: ListExpensesByTypesResponse,
  service: {
    typeName: "expense.v1.ExpenseAPI"
  }
} as const;

/**
 * @generated from rpc expense.v1.ExpenseAPI.RemoveExpense
 */
export const removeExpense = {
  localName: "removeExpense",
  name: "RemoveExpense",
  kind: MethodKind.Unary,
  I: RemoveExpenseRequest,
  O: RemoveExpenseResponse,
  service: {
    typeName: "expense.v1.ExpenseAPI"
  }
} as const;
