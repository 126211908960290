// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file expense/v1/expense_api.proto (package expense.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { ExpenseType } from "../../base/v1/expense_pb.js";
import { Expense } from "./expense_pb.js";

/**
 * @generated from message expense.v1.AddExpenseRequest
 */
export const AddExpenseRequest = /*@__PURE__*/ proto3.makeMessageType(
  "expense.v1.AddExpenseRequest",
  () => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(ExpenseType) },
    { no: 5, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "date", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message expense.v1.AddExpenseResponse
 */
export const AddExpenseResponse = /*@__PURE__*/ proto3.makeMessageType(
  "expense.v1.AddExpenseResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message expense.v1.ListExpensesRequest
 */
export const ListExpensesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "expense.v1.ListExpensesRequest",
  [],
);

/**
 * @generated from message expense.v1.ListExpensesResponse
 */
export const ListExpensesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "expense.v1.ListExpensesResponse",
  () => [
    { no: 1, name: "expenses", kind: "message", T: Expense, repeated: true },
  ],
);

/**
 * @generated from message expense.v1.ListExpensesByTypesRequest
 */
export const ListExpensesByTypesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "expense.v1.ListExpensesByTypesRequest",
  () => [
    { no: 1, name: "types", kind: "enum", T: proto3.getEnumType(ExpenseType), repeated: true },
    { no: 2, name: "from", kind: "message", T: Timestamp },
    { no: 3, name: "to", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message expense.v1.ListExpensesByTypesResponse
 */
export const ListExpensesByTypesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "expense.v1.ListExpensesByTypesResponse",
  () => [
    { no: 1, name: "expenses", kind: "message", T: Expense, repeated: true },
  ],
);

/**
 * @generated from message expense.v1.RemoveExpenseRequest
 */
export const RemoveExpenseRequest = /*@__PURE__*/ proto3.makeMessageType(
  "expense.v1.RemoveExpenseRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message expense.v1.RemoveExpenseResponse
 */
export const RemoveExpenseResponse = /*@__PURE__*/ proto3.makeMessageType(
  "expense.v1.RemoveExpenseResponse",
  [],
);

