// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file expense/v1/expense.proto (package expense.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { ExpenseType } from "../../base/v1/expense_pb.js";

/**
 * @generated from message expense.v1.Expense
 */
export const Expense = /*@__PURE__*/ proto3.makeMessageType(
  "expense.v1.Expense",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(ExpenseType) },
    { no: 6, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "date", kind: "message", T: Timestamp },
    { no: 8, name: "created_at", kind: "message", T: Timestamp },
    { no: 9, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

