import { useEffect, useState } from "react";
import constate from "constate";
import { getUserAggregateProfile } from "proto/aggregate/v1/aggregate_api-AggregateAPI_connectquery";
import { useQuery } from "@connectrpc/connect-query";
import {
  ActionPerformed,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { authAPIWithSessionClient, notificationAPIClient } from "clients";
import { App as CapacitorApp, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "react-router-dom";
import { getNotificationsCount } from "proto/notification/v1/notification_api-NotificationAPI_connectquery";
import {
  canReadNotification,
  fieldsToObject,
  linkForNotification,
} from "notification/helper";
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { SortDirection } from "proto/base/v1/sort_pb";

const useAggProfile = () => {
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    getUserAggregateProfile
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

const useGetNotificationCount = () => {
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    getNotificationsCount
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  await PushNotifications.register();
};

const useGlobalState = () => {
  const [loaded, setLoaded] = useState(false);
  const { data, error, isError, isLoading, refetch } = useAggProfile();
  const {
    data: notificationCountData,
    error: notificationError,
    isError: isNotificationError,
    isLoading: isNotificationLoading,
    refetch: refetchNotification,
  } = useGetNotificationCount();

  const navigate = useNavigate();

  // we will only show loading for the time we fetch data
  useEffect(() => {
    if (loaded || isLoading) return;
    setLoaded(true);
  }, [loaded, isLoading]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    const register = async () => {
      try {
        await registerNotifications();

        // On success, we should be able to receive notifications
        await PushNotifications.addListener(
          "registration",
          async (token: Token) => {
            try {
              await authAPIWithSessionClient.setFCMToken({
                token: token.value,
              });
            } catch (error) {
              console.log(error);
            }
          }
        );

        // On success, we should be able to receive notifications
        await PushNotifications.addListener("registration", async () => {
          try {
            await PushNotifications.removeAllDeliveredNotifications();
          } catch (error) {
            console.log(error);
          }
        });

        // Some issue with our setup and push will not work
        await PushNotifications.addListener(
          "registrationError",
          (error: any) => {
            console.log("Error on registration: " + JSON.stringify(error));
          }
        );

        // Method called when tapping on a notification
        await PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification: ActionPerformed) => {
            const data = notification.notification.data as {
              [key: string]: string;
            };

            const notificationType = Number.parseInt(data.type);

            const link = linkForNotification(notificationType, data);
            const notificationId = data.notification_id;

            if (
              notificationId.length > 0 &&
              canReadNotification(notificationType)
            ) {
              notificationAPIClient
                .readNotification({
                  notificationId: notificationId,
                })
                .then(() => {
                  notificationAPIClient
                    .listNotifications({
                      page: 0,
                      pageSize: 0,
                      sortDirection: SortDirection.DESCENDING,
                    })
                    .then((res) => {
                      notificationAPIClient
                        .readAllNotification({
                          notificationIds: res?.notifications
                            .filter((n) =>
                              canReadNotification(n.notificationType)
                            )
                            .filter(
                              (n) =>
                                linkForNotification(
                                  n.notificationType,
                                  fieldsToObject(n.fields)
                                ) === link
                            )
                            .map((n) => n.id),
                        })
                        .then(() => {
                          refetchNotification();
                        });
                    })
                    .finally(() => {
                      refetchNotification();
                    });
                })
                .catch((e) => {
                  console.log(e);
                });
            }

            if (link.length > 0) {
              navigate(link);
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    };

    register();

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [navigate, refetchNotification]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });

    CapacitorApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slug = event.url.split(".in").pop();
      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [navigate]);

  useEffect(() => {
    const run = async () => {
      try {
        const result = await AppUpdate.getAppUpdateInfo();
        console.log(JSON.stringify(result));
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    };
    run();
  }, []);

  return {
    data,
    error,
    isError,
    loaded,
    isFetching: isLoading,
    refetch,
    notificationCountData,
    notificationError,
    isNotificationError,
    isNotificationLoading,
    refetchNotification,
  };
};

const [GlobalStateProvider, useGlobalStateContext] = constate(useGlobalState);

export { GlobalStateProvider, useGlobalStateContext };
