// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file room/v1/room_api.proto (package room.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AffiliateRoom, Room, SessionRule } from "./room_pb.js";
import { AccessType } from "../../base/v1/access_pb.js";
import { User } from "../../base/v1/user_pb.js";

/**
 * @generated from message room.v1.CreateRoomRequest
 */
export const CreateRoomRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.CreateRoomRequest",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "org_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "session_rules", kind: "message", T: SessionRule, repeated: true },
    { no: 6, name: "default_fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "billing_cycle_day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "billing_cycle_creation_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message room.v1.CreateRoomResponse
 */
export const CreateRoomResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.CreateRoomResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.ListRoomsRequest
 */
export const ListRoomsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.ListRoomsRequest",
  [],
);

/**
 * @generated from message room.v1.ListRoomsResponse
 */
export const ListRoomsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.ListRoomsResponse",
  () => [
    { no: 1, name: "rooms", kind: "message", T: Room, repeated: true },
  ],
);

/**
 * @generated from message room.v1.ListAffiliateRoomsRequest
 */
export const ListAffiliateRoomsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.ListAffiliateRoomsRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.ListAffiliateRoomsResponse
 */
export const ListAffiliateRoomsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.ListAffiliateRoomsResponse",
  () => [
    { no: 1, name: "rooms", kind: "message", T: Room, repeated: true },
  ],
);

/**
 * @generated from message room.v1.ListInvitedRoomsRequest
 */
export const ListInvitedRoomsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.ListInvitedRoomsRequest",
  [],
);

/**
 * @generated from message room.v1.ListInvitedRoomsResponse
 */
export const ListInvitedRoomsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.ListInvitedRoomsResponse",
  () => [
    { no: 1, name: "rooms", kind: "message", T: Room, repeated: true },
  ],
);

/**
 * @generated from message room.v1.GetRoomRequest
 */
export const GetRoomRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.GetRoomRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.GetRoomResponse
 */
export const GetRoomResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.GetRoomResponse",
  () => [
    { no: 1, name: "room", kind: "message", T: Room },
  ],
);

/**
 * @generated from message room.v1.GetAffiliateRoomRequest
 */
export const GetAffiliateRoomRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.GetAffiliateRoomRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.GetAffiliateRoomResponse
 */
export const GetAffiliateRoomResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.GetAffiliateRoomResponse",
  () => [
    { no: 1, name: "room", kind: "message", T: AffiliateRoom },
  ],
);

/**
 * @generated from message room.v1.AddRoomUserRequest
 */
export const AddRoomUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.AddRoomUserRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "access_type", kind: "enum", T: proto3.getEnumType(AccessType) },
    { no: 4, name: "add_to_current_bill_cycle", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "custom_fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
);

/**
 * @generated from message room.v1.AddRoomUserResponse
 */
export const AddRoomUserResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.AddRoomUserResponse",
  [],
);

/**
 * @generated from message room.v1.DeleteRoomUserRequest
 */
export const DeleteRoomUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.DeleteRoomUserRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.DeleteRoomUserResponse
 */
export const DeleteRoomUserResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.DeleteRoomUserResponse",
  [],
);

/**
 * @generated from message room.v1.DeleteRoomRequest
 */
export const DeleteRoomRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.DeleteRoomRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message room.v1.DeleteRoomResponse
 */
export const DeleteRoomResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.DeleteRoomResponse",
  [],
);

/**
 * @generated from message room.v1.UpdateUserRoomPermissionRequest
 */
export const UpdateUserRoomPermissionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.UpdateUserRoomPermissionRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "access_type", kind: "enum", T: proto3.getEnumType(AccessType) },
  ],
);

/**
 * @generated from message room.v1.UpdateUserRoomPermissionResponse
 */
export const UpdateUserRoomPermissionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.UpdateUserRoomPermissionResponse",
  [],
);

/**
 * @generated from message room.v1.ListAllRoomPlayersRequest
 */
export const ListAllRoomPlayersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.ListAllRoomPlayersRequest",
  [],
);

/**
 * @generated from message room.v1.ListAllRoomPlayersResponse
 */
export const ListAllRoomPlayersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.ListAllRoomPlayersResponse",
  () => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
  ],
);

/**
 * @generated from message room.v1.UpdateRoomFeeSettingRequest
 */
export const UpdateRoomFeeSettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.UpdateRoomFeeSettingRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "default_fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "billing_cycle_day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "billing_cycle_creation_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message room.v1.UpdateRoomFeeSettingResponse
 */
export const UpdateRoomFeeSettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.UpdateRoomFeeSettingResponse",
  [],
);

/**
 * @generated from message room.v1.UpdateRoomEntityCustomFeeRequest
 */
export const UpdateRoomEntityCustomFeeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.UpdateRoomEntityCustomFeeRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "custom_fees", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
);

/**
 * @generated from message room.v1.UpdateRoomEntityCustomFeeResponse
 */
export const UpdateRoomEntityCustomFeeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.UpdateRoomEntityCustomFeeResponse",
  [],
);

