import {
  extendTheme,
  withDefaultColorScheme,
  theme as baseTheme,
} from "@chakra-ui/react";

const popoverTheme = extendTheme(
  {
    colors: {
      brand: baseTheme.colors.facebook,
    },
    fonts: {
      body: "Inter, system-ui, sans-serif",
      heading: "Work Sans, system-ui, sans-serif",
    },
  },
  withDefaultColorScheme({ colorScheme: "brand" }),
);
export default popoverTheme;
