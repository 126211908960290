// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file notification/v1/notification_api.proto (package notification.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetNotificationsCountRequest, GetNotificationsCountResponse, ListNotificationsRequest, ListNotificationsResponse, ReadAllNotificationRequest, ReadAllNotificationResponse, ReadNotificationRequest, ReadNotificationResponse } from "./notification_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * NotificationAPI service
 *
 * @generated from service notification.v1.NotificationAPI
 */
export const NotificationAPI = {
  typeName: "notification.v1.NotificationAPI",
  methods: {
    /**
     * @generated from rpc notification.v1.NotificationAPI.ListNotifications
     */
    listNotifications: {
      name: "ListNotifications",
      I: ListNotificationsRequest,
      O: ListNotificationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc notification.v1.NotificationAPI.GetNotificationsCount
     */
    getNotificationsCount: {
      name: "GetNotificationsCount",
      I: GetNotificationsCountRequest,
      O: GetNotificationsCountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc notification.v1.NotificationAPI.ReadNotification
     */
    readNotification: {
      name: "ReadNotification",
      I: ReadNotificationRequest,
      O: ReadNotificationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc notification.v1.NotificationAPI.ReadAllNotification
     */
    readAllNotification: {
      name: "ReadAllNotification",
      I: ReadAllNotificationRequest,
      O: ReadAllNotificationResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

